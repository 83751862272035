import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from 'App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.scss';

if (process.env.REACT_APP_ENVIRONMENT !== 'development' && process.env.REACT_APP_API_URL !== undefined && process.env.REACT_APP_SENTRY_DSN !== undefined) {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: function (integrations) {
      return [
        ...integrations,
        new BrowserTracing({
          tracingOrigins: [`${process.env.REACT_APP_API_URL}`],
        }),
      ];
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    sampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
