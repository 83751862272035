import Dexie, { Table } from 'dexie';
import { VendorInterface, categoryInterface, ProductInterface } from './types';

export class ImportToolDexie extends Dexie {
  vendors!: Table<Pick<VendorInterface, 'vendors_id'>>;
  categories!: Table<Pick<categoryInterface, 'vendor_products_category'>>;
  selectedProducts!: Table<ProductInterface>;
  editedProducts!: Table<ProductInterface>;

  constructor() {
    super('importTool');

    this.version(3).stores({
      vendors: '&vendors_id',
      categories: '&vendor_products_category',
      selectedProducts: '&vendor_products_id',
      editedProducts: '&vendor_products_id, vendor_products_sku, vendor_products_name, vendor_products_category, vendor_products_price',
    });
  }
}

export const dexieDB = new ImportToolDexie();
