import React, { useEffect, useState } from 'react';

import { useLiveQuery } from 'dexie-react-hooks';
import { dexieDB } from './shared/db';

//Pages
import { SelectVendorPage } from './sub-pages/select-vendor';
import { SelectCategoriesPage } from './sub-pages/select-categories';
import { SelectProductsPage } from './sub-pages/select-products';
import { EditProductsPage } from './sub-pages/edit-products';
import { ConfirmImportPage } from './sub-pages/confirm-import';

import { Button, Col, Row, Tab, Nav, Card, Container } from 'react-bootstrap';

export const ImportProductsPage: React.FC = () => {
  const [currentSubPage, setCurrentSubPage] = useState(localStorage.getItem('import-tool-currentSubPage') || 'select-vendor');
  const [canContinue, setCanContinue] = useState<string>(localStorage.getItem('import-tool-canContinue') || 'false');

  useEffect(() => {
    localStorage.setItem('import-tool-currentSubPage', currentSubPage);
  }, [currentSubPage]);

  useEffect(() => {
    if (canContinue) {
      localStorage.setItem('import-tool-canContinue', `${canContinue}`);
    }
  }, [canContinue]);

  const vendorCount: number | undefined = useLiveQuery(() => dexieDB.vendors.count());

  const categoriesCount: number | undefined = useLiveQuery(() => dexieDB.categories.count());

  const selectProductsCount: number | undefined = useLiveQuery(() => dexieDB.selectedProducts.count());

  // Handle prev & next button is-disabled
  useEffect(() => {
    switch (currentSubPage) {
      case 'select-vendor':
        if (vendorCount && vendorCount !== undefined) {
          setCanContinue(`${vendorCount > 0}`);
        } else {
          setCanContinue(`false`);
        }
        break;
      case 'select-categories':
        if (categoriesCount && categoriesCount !== undefined) {
          setCanContinue(`${categoriesCount > 0}`);
        } else {
          setCanContinue(`false`);
        }
        break;
      case 'select-products':
        if (selectProductsCount && selectProductsCount !== undefined) {
          setCanContinue(`${selectProductsCount > 0}`);
        } else {
          setCanContinue(`false`);
        }
        break;
      case 'edit-products':
        setCanContinue(`true`);
        break;
      default:
        setCanContinue(`false`);
    }
  }, [currentSubPage, canContinue, vendorCount, categoriesCount, selectProductsCount]);

  const handleNextPageRequest = () => {
    switch (currentSubPage) {
      case 'select-vendor':
        setCurrentSubPage('select-categories');
        break;
      case 'select-categories':
        setCurrentSubPage('select-products');
        break;
      case 'select-products':
        setCurrentSubPage('edit-products');
        break;
      case 'edit-products':
        setCurrentSubPage('confirm-import');
        break;
      default:
        break;
    }
  };

  const handlePreviousPageRequest = () => {
    switch (currentSubPage) {
      case 'select-categories':
        setCurrentSubPage('select-vendor');
        break;
      case 'select-products':
        setCurrentSubPage('select-categories');
        break;
      case 'edit-products':
        setCurrentSubPage('select-products');
        break;
      case 'confirm-import':
        if (vendorCount && vendorCount > 0 && categoriesCount && categoriesCount > 0 && selectProductsCount && selectProductsCount > 0) {
          setCurrentSubPage('edit-products');
        } else {
          setCurrentSubPage('select-vendor');
        }
        break;
      default:
        break;
    }
  };

  return (
    <Row>
      <Container className="mt-4">
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Tab.Container id="left-tabs-steps" defaultActiveKey="first">
                  <Row>
                    <Col sm={12}>
                      <h2>Proces</h2>
                      <p>Følg nedenstående step for at importere produkter</p>

                      <Nav variant="pills" className="steps">
                        <Nav.Item>
                          <Nav.Link eventKey="select-vendor" className={`${currentSubPage === 'select-vendor' ? 'active' : 'inactive'} `} disabled>
                            Forhandler
                          </Nav.Link>
                        </Nav.Item>
                        <div className="arrow-wrapper">
                          <span className="arrow"></span>
                        </div>
                        <Nav.Item>
                          <Nav.Link eventKey="select-categories" className={`${currentSubPage === 'select-categories' ? 'active' : 'inactive'} `} disabled>
                            Kategori
                          </Nav.Link>
                        </Nav.Item>
                        <div className="arrow-wrapper">
                          <span className="arrow"></span>
                        </div>
                        <Nav.Item>
                          <Nav.Link eventKey="select-products" className={`${currentSubPage === 'select-products' ? 'active' : 'inactive'} `} disabled>
                            Produkter
                          </Nav.Link>
                        </Nav.Item>
                        <div className="arrow-wrapper">
                          <span className="arrow"></span>
                        </div>
                        <Nav.Item>
                          <Nav.Link eventKey="edit-products" className={`${currentSubPage === 'edit-products' ? 'active' : 'inactive'} `} disabled>
                            Rediger
                          </Nav.Link>
                        </Nav.Item>
                        <div className="arrow-wrapper">
                          <span className="arrow"></span>
                        </div>
                        <Nav.Item>
                          <Nav.Link eventKey="confirm-import" className={`${currentSubPage === 'confirm-import' ? 'active' : 'inactive'} `} disabled>
                            Godkend
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="import-container">
        <Card>
          <Card.Body>
            <Row>
              <Col sm={12}>
                {(() => {
                  switch (currentSubPage) {
                    case 'select-vendor':
                      return <SelectVendorPage />;
                    case 'select-categories':
                      return <SelectCategoriesPage />;
                    case 'select-products':
                      return <SelectProductsPage />;
                    case 'edit-products':
                      return <EditProductsPage />;
                    case 'confirm-import':
                      return <ConfirmImportPage />;
                    default:
                      return <p>you are not supposed to be here &apos;{currentSubPage}&apos;</p>;
                  }
                })()}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

      <Container className="footer-navigation">
        <Row className="justify-content-center">
          <Col sm={10} lg={8}>
            <Card>
              <Card.Body>
                <Row className="m-3">
                  <Col sm={12}>
                    <Row>
                      <Col xs={6}>
                        {currentSubPage !== 'select-vendor' && (
                          <Button className="w-100 button-orange previous" onClick={handlePreviousPageRequest}>
                            Tilbage
                          </Button>
                        )}
                      </Col>
                      <Col xs={6}>
                        {currentSubPage !== 'confirm-import' && (
                          <Button className="w-100 next" onClick={handleNextPageRequest} disabled={canContinue === 'false'}>
                            Næste
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};
