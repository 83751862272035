import React, { useEffect, useState } from 'react';
import { getVendors, createVendor, deleteVendor } from 'Api';
import { Vendor } from './types';
import { Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';

export const CsvImportPage: React.FC = () => {
  const [vendor, setVendor] = useState<Vendor | null>({});
  const [allVendors, setAllVendors] = useState<Vendor[]>([]);
  const [statusText, setStatusText] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const vendors = async (): Promise<void> => {
      const repsonse = await getVendors();

      if (repsonse) {
        setAllVendors(repsonse.data);
      }
    };

    vendors();
  }, []);

  const handleProductValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let value: File | string;

    if (event.target.type === 'file') {
      if (event.target.files !== null) {
        value = event.target.files[0];
      }
    } else {
      value = event.target.value;
    }

    setVendor((prevValue) => ({
      ...prevValue,
      [event.target.id]: value,
    }));
  };

  const handleFormSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (vendor === undefined || vendor === null) {
      return;
    }

    const formData = new FormData();
    for await (const [key, value] of Object.entries(vendor)) {
      formData.append(key, value);
    }

    setLoading(true);

    const response = await createVendor(formData);

    if (response) {
      if (response.data.status) {
        setAllVendors([...allVendors, response.data.vendor]);
        setStatusText(response.status + ': ' + response.data.message + '. Vendor: ' + response.data.vendor.vendors_name + ' uploaded');
      } else {
        setStatusText(response.status + ' ' + response.statusText);
      }
    }

    setLoading(false);
  };

  const handleRemoveVendor = async (vendorId: number): Promise<void> => {
    const response = await deleteVendor(vendorId);

    if (response) {
      if (response.data.status) {
        setAllVendors(allVendors.filter((vendor) => vendor.vendors_id !== response.data.vendor.vendors_id));
        setStatusText(response.status + ': ' + response.data.message + '. Vendor: ' + response.data.vendor.vendors_name + ' deleted');
      } else {
        setStatusText(response.status + ' ' + response.statusText);
      }
    }
  };

  return (
    <Row className="mt-4">
      <Col sm={12}>
        <Card>
          <Card.Header>CSV Import</Card.Header>
          <Card.Body>
            <b>Tilføj leverandør til Import Tool</b>

            <Form className="mt-3" encType="multipart/form-data" acceptCharset="utf-8" onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3" controlId="vendors_name">
                <Form.Label>Leverandør navn</Form.Label>
                <Form.Control type="text" placeholder="Indtast leverandør navn" required onChange={handleProductValueChanged} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="vendors_image_url">
                <Form.Label>Leverandør logo</Form.Label>
                <Form.Control type="file" required onChange={handleProductValueChanged} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="vendors_industry">
                <Form.Label>Branche</Form.Label>
                <Form.Control type="text" placeholder="Indtast branche" required onChange={handleProductValueChanged} />
                <Form.Text className="text-muted">Indtast navn på branche</Form.Text>
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload CSV fil</Form.Label>
                <Form.Control type="file" onChange={handleProductValueChanged} required />
              </Form.Group>

              <Button variant="primary" type="submit">
                Upload
                {loading && (
                  <>
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </>
                )}
              </Button>
            </Form>
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Header>Debug</Card.Header>
          <Card.Body>
            {loading && (
              <>
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </>
            )}
            {statusText}
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Header>Liste over leverandører</Card.Header>
          <Card.Body>
            <Row className="pt-4">
              {allVendors.map((vendorData) => (
                <Col key={vendorData.vendors_id} sm={6} lg={4} xl={3}>
                  <Card className="vendor-card">
                    <Card.Body>
                      <Card.Title>{vendorData.vendors_name}</Card.Title>
                      {vendorData.vendors_import_type === 'csv' ? (
                        <Button onClick={() => handleRemoveVendor(vendorData.vendors_id ? vendorData.vendors_id : 0)}>Slet</Button>
                      ) : (
                        'API'
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
