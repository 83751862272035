import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUsersContext } from 'contexts/users';
import { Row, Col, Button, Card, ListGroup } from 'react-bootstrap';

export const DashboardPage: React.FC = () => {
  const { usersState } = useUsersContext();

  const history = useHistory();
  const redirect = () => {
    history.push('/import-products');
  };

  const isApiConnected = (): boolean => {
    if (usersState.user.apiConnected) {
      return usersState.user.apiConnected;
    }

    return false;
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card className="mt-4">
            <Card.Header>Dashboard</Card.Header>
            <Card.Body>
              <Card.Title className="dashboard-title">Hej, {usersState.user.company}.</Card.Title>

              <Card.Text as="div">
                <b>Velkommen til IT Stack&apos;s Import Tool</b>
                <p>Her kan du se general information og nyheder i forhold til værktøjet.</p>
              </Card.Text>

              <ListGroup className="dashboard-list" as="ol" numbered variant="flush">
                <ListGroup.Item as="li">
                  Start med at udfylde informationerne fra din WooCommerce REST API under Indstillinger - Integration.{' '}
                  <span className="d-block text-muted">Se guide under integration eller kontakt os, hvis du har brug for hjælp.</span>
                </ListGroup.Item>
                <ListGroup.Item as="li">Husk altid at tage en backup af din webshop inden du påbegynder et import.</ListGroup.Item>
                <ListGroup.Item as="li">God fornøjelse!</ListGroup.Item>
              </ListGroup>

              {isApiConnected() === true && (
                <Button variant="primary" onClick={redirect}>
                  Start import her!
                </Button>
              )}

              <Row className="mt-5">
                <Col>
                  <a href="https://www.itstack.dk/handelsbetingelser/" target="_blank" rel="noreferrer">
                    Vilkår for Import Tool
                  </a>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mt-5">
            <Card.Body>
              <Card.Title>Mangler du en leverandør?</Card.Title>
              <Card.Text as="div">
                <p>Vi tilføjer løbende flere leverandører til vores Import Tool.</p>
                <p>
                  Hvis du har en leverandør, som du gerne vil have med i vores Import Tool, kan du sende en mail til:
                  <br />
                  <a href="mailto:mail@itstack.dk">mail@itstack.dk</a>
                </p>
                <p>
                  <strong>Bemærk!</strong> Vi foretrækker dataudtræk fra API, Excel / CSV, og XML.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
