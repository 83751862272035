export default async function debugLog(message: unknown, { enable }: { enable: boolean }): Promise<void> {
  if (process.env.NODE_ENV === 'development' && enable === true) {
    console.log(message);
  }
}

async function debugError(message: unknown, { enable }: { enable: boolean }): Promise<void> {
  if (process.env.NODE_ENV === 'development' && enable === true) {
    console.error(message);
  }
}

async function debugWarn(message: unknown, { enable }: { enable: boolean }): Promise<void> {
  if (process.env.NODE_ENV === 'development' && enable === true) {
    console.warn(message);
  }
}

async function debugInfo(message: unknown, { enable }: { enable: boolean }): Promise<void> {
  if (process.env.NODE_ENV === 'development' && enable === true) {
    console.info(message);
  }
}

async function debugColor(message: unknown, { enable, color }: { enable: boolean; color: string }): Promise<void> {
  if (process.env.NODE_ENV === 'development' && enable === true) {
    console.log(`%c${message}`, `color: ${color}`);
  }
}

export { debugError, debugWarn, debugInfo, debugColor };
