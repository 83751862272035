import React, { useEffect } from 'react';

//import { getWebshopProducts } from "Api";

export const MyProductsPage: React.FC = () => {
  // const [products, setProducts] = useState<any>([]);
  useEffect(() => {
    /*getWebshopProducts().then((resp) => {
      resp.json().then((products) => setProducts(products));
    });*/
  }, []);
  return (
    <>
      <h1>Mine produkter</h1>
      <h4>Her kan du se dine produkter i din webshop</h4>
    </>
  );
};
