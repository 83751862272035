import React from 'react';
import { useUsersContext } from 'contexts/users';
import { Row, Col, Card, ListGroup } from 'react-bootstrap';

export const AccountPage: React.FC = () => {
  const { usersState } = useUsersContext();

  return (
    <>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>Konto</Card.Header>
            <Card.Body>
              <b>Oplysninger</b>

              <ListGroup variant="flush">
                <ListGroup.Item>ID: {usersState.user.id}</ListGroup.Item>
                <ListGroup.Item>Firma: {usersState.user.company}</ListGroup.Item>
                <ListGroup.Item>Email: {usersState.user.email}</ListGroup.Item>
                <ListGroup.Item>Kodeord: ******</ListGroup.Item>
                <ListGroup.Item>Rolle: {usersState.user.role[0] === 'admin' ? 'Admininistrator' : 'Bruger'}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
