import { login } from 'Api';
import { useUsersContext } from 'contexts/users';
import React, { useState } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';

import { UsersActions } from 'contexts/users/types';

//Logo
import companyLogo from '../../img/itstack-logo-green-animated-1.svg';

export const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { usersDispatch } = useUsersContext();

  const [errorText, setErrorText] = useState('');

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    if (email && password) {
      const loginAction = await login(email, password);

      if (loginAction) {
        if (loginAction.data.error) {
          setErrorText(loginAction.data.error);
        } else {
          usersDispatch({ type: UsersActions.LOGIN, payload: loginAction.data.token });
        }
      }
    }
  };

  return (
    <>
      <Container>
        <Row className="form-signin justify-content-center">
          <Col sm={10}>
            <Form>
              <Row>
                <Col sm={12} className="text-center">
                  <img src={companyLogo} className="logo" alt="IT Stack logo med animation" loading="lazy" />
                </Col>
                <Col sm={12}>
                  <h2 className="mb-4">Log ind på din konto</h2>
                </Col>
              </Row>

              <Row>
                <Form.Group className="mb-3" controlId="formHorizontalEmail">
                  <Col>
                    <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </Col>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formHorizontalPassword">
                  <Col>
                    <Form.Control type="password" placeholder="Kodeord" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </Col>
                </Form.Group>

                <Form.Group className="mb-3 text-center">
                  <Col>
                    <Button type="submit" onClick={(e) => handleLogin(e)}>
                      Log ind
                    </Button>
                  </Col>
                </Form.Group>

                <Col>
                  <p className="error text-center">{errorText}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <a href="/#">Opret bruger</a>
                </Col>
                <Col>
                  <a className="reset-password" href="/#">
                    Nulstil kodeord
                  </a>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
