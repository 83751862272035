import React, { ChangeEvent, useEffect, useState } from 'react';
import { ProductInterface, CategoryInterface, ChildVariation } from 'pages/import-products/shared/types';
import { VariableEditProps } from './types';
import { useSelectedProducts } from 'contexts/selected-products';
import Select, { components, OnChangeValue, OptionProps } from 'react-select';
import { dexieDB } from 'pages/import-products/shared/db';

const EditVariableProducts: React.FC<React.PropsWithChildren<VariableEditProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');
  const { selectedProducts, setSelectedProducts } = useSelectedProducts();
  const [selectedVariations, setSelectedVariations] = useState<number[]>([]);
  const [isEditing, setIsEditing] = useState<{ key: keyof ProductInterface | keyof ChildVariation; id: number | undefined } | undefined>(undefined);
  const [product, setProduct] = useState<ProductInterface | undefined | null>(props.product);
  const [variationProducts, setVariationProducts] = useState<ChildVariation[] | undefined | null>(props.product.product_variations);
  const [inputWidth, setInputWidth] = useState<string>('');
  const [savedProduct, setSavedProduct] = useState<string>('');
  const [savedVariation, setSavedVariation] = useState<{ field: string; variation: number } | undefined>(undefined);

  useEffect(() => {
    if (!props.product.product_variations) {
      return;
    }
    if (selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)) {
      const variationIds = props.product.product_variations
        .map((variation) => variation.vendor_product_variations_id)
        .filter((id): id is number => id !== undefined);

      setSelectedVariations(variationIds);
    } else {
      setSelectedVariations([]);
    }
  }, [selectedProducts, props.product]);

  const handleProductClick = (product: ProductInterface) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const isProductSelected = selectedProducts.some((selected) => selected.vendor_products_id === product.vendor_products_id);

    setSelectedProducts((currentProducts) =>
      isProductSelected ? currentProducts.filter((selected) => selected.vendor_products_id !== product.vendor_products_id) : [...currentProducts, product],
    );
  };

  const handleVariationClick = (variation: ChildVariation) => (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    const variationId = variation.vendor_product_variations_id;
    if (variationId === undefined) return;

    setSelectedVariations((currentVariations) =>
      currentVariations.includes(variationId) ? currentVariations.filter((id) => id !== variationId) : [...currentVariations, variationId],
    );
  };

  const onProductSelect = (tmpProduct: ProductInterface): void => {
    props.setProductUnderEdit(tmpProduct);
    props.setIsSimpleModalOpen(true);
  };

  const onVariableProductSelect = (tmpProduct: ProductInterface, variantId: number | undefined): void => {
    if (!variantId) {
      return;
    }

    props.setProductUnderEdit(tmpProduct);
    props.setVariationId(variantId);
    props.setIsVariableModalOpen(true);
  };

  const categoryOptions: React.FC<OptionProps<CategoryInterface, true>> = (props) => {
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null} // react-select handles selection, so no action needed here
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    );
  };

  const handleEditing = (key: keyof ProductInterface | keyof ChildVariation, id?: number) => {
    if (id) {
      setIsEditing({ key, id });
    } else {
      setIsEditing({ key, id: undefined });
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>, variation?: ChildVariation) => {
    if (variation?.vendor_product_variations_id) {
      const field = (event.target as HTMLInputElement).id;
      setSavedVariation({ field, variation: variation.vendor_product_variations_id });
      setTimeout(() => {
        setSavedVariation(undefined);
      }, 4000);
    } else {
      const id = (event.target as HTMLInputElement).id;
      setSavedProduct(id);
      setTimeout(() => {
        setSavedProduct('');
      }, 4000);
    }

    handleProductSave();
    setIsEditing(undefined);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, variation?: ChildVariation) => {
    if (event.key === 'Enter') {
      if (variation?.vendor_product_variations_id) {
        const field = (event.target as HTMLInputElement).id;
        setSavedVariation({ field, variation: variation.vendor_product_variations_id });
        setTimeout(() => {
          setSavedVariation(undefined);
        }, 4000);
      } else {
        const id = (event.target as HTMLInputElement).id;
        setSavedProduct(id);
        setTimeout(() => {
          setSavedProduct('');
        }, 4000);
      }

      handleProductSave();
      setIsEditing(undefined);
    }
  };

  const handleProductValueChanged = (field: keyof ProductInterface, value: ProductInterface[keyof ProductInterface]) => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }

      return {
        ...prevData,
        [field]: value,
      } as ProductInterface;
    });
  };

  const handleVariationValueChanged = (
    field: keyof ChildVariation,
    value: ChildVariation[keyof ChildVariation],
    selectedVariation: ChildVariation,
    event?: ChangeEvent<HTMLInputElement>,
  ) => {
    if (event) {
      setInputWidth(event.target.value);
    }
    setVariationProducts((prevData) => {
      if (!Array.isArray(prevData)) {
        return prevData;
      }

      const updatedVariations = prevData.map((variation) => {
        if (variation.vendor_product_variations_id === selectedVariation.vendor_product_variations_id) {
          return {
            ...variation,
            [field]: value,
          };
        }

        return variation;
      });

      handleProductValueChanged('product_variations', updatedVariations);
      return updatedVariations;
    });
  };

  const handleEditCategories = (newValue: OnChangeValue<CategoryInterface, true>): void => {
    setProduct((prevData) => {
      if (!prevData) {
        return prevData;
      }
      return {
        ...prevData,
        vendor_products_category: newValue as CategoryInterface[],
      };
    });
  };

  const handleProductSave = async (): Promise<void> => {
    if (!product) {
      return;
    }

    await dexieDB.editedProducts.update(product.vendor_products_id, product);
  };

  const getProductStatus = (): string => {
    if (props.product.vendor_products_status) {
      return props.product.vendor_products_status === 'draft' ? 'Kladde' : 'Udgiv';
    } else {
      return (props.product.vendor_products_status = 'draft');
    }
  };

  const editableSrc = (field: string[] | string, variation?: number) => {
    if (variation) {
      if (Array.isArray(field)) {
        return field.some((field) => savedVariation?.field === field) && savedVariation?.variation === variation
          ? '/fa/svgs/solid/check.svg'
          : '/fa/svgs/solid/pen.svg';
      }
      return savedVariation?.field === field && savedVariation.variation === variation ? '/fa/svgs/solid/check.svg' : '/fa/svgs/solid/pen.svg';
    }
    return savedProduct === field ? '/fa/svgs/solid/check.svg' : '/fa/svgs/solid/pen.svg';
  };

  const editableClass = (field: string[] | string, variation?: number) => {
    if (variation) {
      if (Array.isArray(field)) {
        return `editable-icon ${
          field.some((field) => savedVariation?.field === field) && savedVariation?.variation === variation ? 'editable-icon-focus' : ''
        }`;
      }
      return `editable-icon ${savedVariation?.field === field && savedVariation.variation === variation ? 'editable-icon-focus' : ''}`;
    }
    return `editable-icon ${savedProduct === field ? 'editable-icon-focus' : ''}`;
  };

  return (
    <>
      <tr className={selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id) ? 'table-light' : ''}>
        <td className="product-simple-item text-center" onClick={handleProductClick(props.product)}>
          <div className="edit-custom-checkbox justify-content-end">
            <label>
              <input type="checkbox" onClick={(event) => event.stopPropagation()} />
              <span className="edit-custom-checkbox-check" aria-hidden="true">
                <img
                  className={selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id) ? '' : 'hidden'}
                  src="/fa/svgs/solid/check.svg"
                />
              </span>
            </label>
          </div>
        </td>

        <td className="product-simple-item" onClick={handleProductClick(props.product)}>
          <img height={50} src={props.product.vendor_products_image} alt="alt" />
        </td>

        <td className="product-simple-item d-none d-sm-table-cell">
          <div className="multi-line-wrapper">
            <label>{props.product.product_variations?.[0].vendor_product_variations_sku}</label>
            <small>{props.product.product_variations?.[0].vendor_product_variations_ean}</small>
          </div>
        </td>

        <td className="product-simple-item">
          <label>{props.product.vendor_products_name}</label>
        </td>

        <td
          className="product-simple-item product-simple-item-editable d-none d-sm-table-cell"
          onClick={() => {
            handleEditing('vendor_products_category');
          }}
        >
          {isEditing && isEditing.key === 'vendor_products_category' ? (
            <>
              <Select
                inputId="vendor_products_category"
                options={props.webshopCategories}
                placeholder="Vælg kategori"
                isSearchable={true}
                isMulti={true}
                getOptionValue={(option: CategoryInterface) => option.id}
                getOptionLabel={(option: CategoryInterface) => `${option.name}`}
                components={{ Option: categoryOptions, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                onChange={handleEditCategories}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                noOptionsMessage={() => `${'Ingen kategorier'}`}
                defaultValue={props.product.vendor_products_category as CategoryInterface[]}
                menuIsOpen
                autoFocus
              />
              <img className="editable-icon editable-icon-focus" src={editableSrc('vendor_products_category')} />
            </>
          ) : (
            <>
              <label className="product-simple-item-editable-label">
                {props.product.vendor_products_category?.length ? (
                  <>
                    {props.product.vendor_products_category instanceof Array
                      ? props.product.vendor_products_category.map((category: string | CategoryInterface, index, array) => {
                          const separator = index < array.length - 1 ? ', ' : '';
                          return typeof category === 'object' ? category.name + separator : category + separator;
                        })
                      : 'Tilføj kategori'}
                  </>
                ) : (
                  <p className="mb-0">Tilføj kategori</p>
                )}
              </label>
              <img className={editableClass('vendor_products_category')} src={editableSrc('vendor_products_category')} />
            </>
          )}
        </td>

        <td className="product-simple-item"></td>

        <td
          className="product-simple-item product-simple-item-editable d-none d-sm-table-cell"
          onClick={() => {
            handleEditing('vendor_products_status');
          }}
        >
          {isEditing && isEditing.key === 'vendor_products_status' ? (
            <>
              <select
                id="vendor_products_status"
                aria-label="status"
                onBlur={handleBlur}
                value={product?.vendor_products_status}
                onChange={(e) => handleProductValueChanged(e.target.id as keyof ProductInterface, e.target.value)}
              >
                <option value="draft">Kladde</option>
                <option value="publish">Udgiv</option>
              </select>
              <img className="editable-icon editable-icon-focus" src={editableSrc('vendor_products_status')} />
            </>
          ) : (
            <>
              <label className="product-simple-item-editable-label">{getProductStatus()}</label>
              <img className={editableClass('vendor_products_status')} src={editableSrc('vendor_products_status')} />
            </>
          )}
        </td>

        <td className="product-simple-item d-none d-sm-none d-md-none d-lg-none d-xl-table-cell"></td>

        <td className="product-simple-item text-center">
          <span className="product-simple-item-edit-btn" onClick={() => onProductSelect(props.product)}>
            <img src="/fa/svgs/regular/edit.svg" />
          </span>
        </td>
      </tr>
      {props.product.product_variations &&
        props.product.product_variations.map((variation, index) => (
          <tr
            key={index}
            className={
              variation.vendor_product_variations_id !== undefined && selectedVariations.includes(variation.vendor_product_variations_id) ? 'table-light' : ''
            }
          >
            <td colSpan={2} className="product-variable-item text-center" onClick={() => handleVariationClick(variation)}>
              <div className="edit-custom-checkbox variable-checkbox justify-content-center">
                <label>
                  <input
                    type="checkbox"
                    className="me-2 ms-4"
                    onClick={(event) => event.stopPropagation()}
                    disabled={!selectedProducts.some((product) => product.vendor_products_id === props.product.vendor_products_id)}
                    readOnly
                  />
                  <span className="edit-custom-checkbox-check" aria-hidden="true">
                    <img
                      className={selectedProducts.some((p) => p.vendor_products_id === props.product.vendor_products_id) ? '' : 'hidden'}
                      src="/fa/svgs/solid/check.svg"
                    />
                  </span>
                </label>
                {variation.vendor_product_variations_image && variation.vendor_product_variations_image.length !== 0 ? (
                  <img height={50} src={variation.vendor_product_variations_image} alt="alt" />
                ) : null}
              </div>
            </td>

            <td className="product-variable-item d-none d-sm-table-cell">
              <div className="multi-line-wrapper">
                <label>{variation.vendor_product_variations_sku}</label>
                <small>{variation.vendor_product_variations_ean}</small>
              </div>
            </td>

            <td className="product-variable-item">
              <label>{variation.vendor_product_variations_name}</label>
            </td>
            <td className="product-variable-item d-none d-sm-table-cell"></td>

            <td className="product-variable-item product-variable-item-editable d-sm-table-cell">
              <div className="d-flex align-items-center">
                <div className="multi-line-wrapper">
                  {variation.vendor_product_variations_sales_price ? (
                    <>
                      {isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id ? (
                        <input
                          id="vendor_product_variations_price"
                          type="number"
                          value={variationProducts?.[index].vendor_product_variations_price}
                          onBlur={(e) => handleBlur(e, variation)}
                          onKeyDown={(e) => handleKeyDown(e, variation)}
                          onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                          onFocus={(e) => setInputWidth(e.target.value)}
                          style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                          autoFocus
                        />
                      ) : (
                        <label
                          className="product-variable-item-editable-label"
                          onClick={() => {
                            handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                          }}
                        >
                          <s>{numberFormatter.format(variation.vendor_product_variations_price)} DKK</s>
                        </label>
                      )}

                      {isEditing && isEditing.key === 'vendor_product_variations_sales_price' && isEditing.id === variation.vendor_product_variations_id ? (
                        <input
                          id="vendor_product_variations_sales_price"
                          type="number"
                          value={variationProducts?.[index].vendor_product_variations_sales_price}
                          onBlur={(e) => handleBlur(e, variation)}
                          onKeyDown={(e) => handleKeyDown(e, variation)}
                          onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                          onFocus={(e) => setInputWidth(e.target.value)}
                          style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                          autoFocus
                        />
                      ) : (
                        <label
                          className="product-variable-item-editable-label"
                          onClick={() => {
                            handleEditing('vendor_product_variations_sales_price', variation.vendor_product_variations_id);
                          }}
                        >
                          {numberFormatter.format(variation.vendor_product_variations_sales_price)} DKK
                        </label>
                      )}
                    </>
                  ) : (
                    <>
                      {(isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id) ||
                      (isEditing && isEditing.key === 'vendor_product_variations_sales_price' && isEditing.id === variation.vendor_product_variations_id) ? (
                        <>
                          {isEditing && isEditing.key === 'vendor_product_variations_price' && isEditing.id === variation.vendor_product_variations_id ? (
                            <input
                              id={'vendor_product_variations_price'}
                              type="number"
                              value={variationProducts?.[index].vendor_product_variations_price}
                              onBlur={(e) => handleBlur(e, variation)}
                              onKeyDown={(e) => handleKeyDown(e, variation)}
                              onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                              onFocus={(e) => setInputWidth(e.target.value)}
                              style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                              autoFocus
                            />
                          ) : (
                            <>
                              <label
                                className="product-variable-item-editable-label"
                                onClick={() => {
                                  handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                                }}
                              >
                                <s>{numberFormatter.format(variation.vendor_product_variations_price)} DKK</s>
                              </label>
                              <input
                                id={'vendor_product_variations_sales_price'}
                                type="number"
                                value={variationProducts?.[index].vendor_product_variations_sales_price}
                                onBlur={(e) => handleBlur(e, variation)}
                                onKeyDown={(e) => handleKeyDown(e, variation)}
                                onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                                onFocus={(e) => setInputWidth(e.target.value)}
                                style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                                autoFocus
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <label
                            className="product-variable-item-editable-label"
                            onClick={() => {
                              handleEditing('vendor_product_variations_price', variation.vendor_product_variations_id);
                            }}
                          >
                            {numberFormatter.format(variation.vendor_product_variations_price)} DKK
                          </label>
                          <label
                            className="product-variable-item-editable-label add-sales"
                            onClick={() => {
                              handleEditing('vendor_product_variations_sales_price', variation.vendor_product_variations_id);
                            }}
                          >
                            + Tilføj udsalgspris
                          </label>
                        </>
                      )}
                    </>
                  )}
                </div>
                <img
                  className={editableClass(
                    ['vendor_product_variations_price', 'vendor_product_variations_sales_price'],
                    variation.vendor_product_variations_id,
                  )}
                  src={editableSrc(['vendor_product_variations_price', 'vendor_product_variations_sales_price'], variation.vendor_product_variations_id)}
                />
              </div>
            </td>

            <td className="product-variable-item d-none d-sm-table-cell"></td>

            <td
              className="product-variable-item product-variable-item-editable d-none d-sm-none d-md-none d-lg-none d-xl-table-cell"
              onClick={() => {
                handleEditing('vendor_product_variations_stock', variation.vendor_product_variations_id);
              }}
            >
              {isEditing && isEditing.key === 'vendor_product_variations_stock' && isEditing.id === variation.vendor_product_variations_id ? (
                <>
                  <input
                    id="vendor_product_variations_stock"
                    type="number"
                    value={variationProducts?.[index].vendor_product_variations_stock}
                    onBlur={(e) => handleBlur(e, variation)}
                    onKeyDown={(e) => handleKeyDown(e, variation)}
                    onChange={(e) => handleVariationValueChanged(e.target.id as keyof ChildVariation, e.target.value, variation, e)}
                    onFocus={(e) => setInputWidth(e.target.value)}
                    style={{ width: `${inputWidth.length + 2 || 1}ch` }}
                    autoFocus
                  ></input>
                  <img
                    className="editable-icon editable-icon-focus"
                    src={editableSrc('vendor_product_variations_stock', variation.vendor_product_variations_id)}
                  />
                </>
              ) : (
                <>
                  <label className="product-variable-item-editable-label">
                    <span
                      className={variation.vendor_product_variations_stock && variation.vendor_product_variations_stock > 0 ? 'instock' : 'out-of-stock'}
                    ></span>
                    {variation.vendor_product_variations_stock} stk.
                  </label>
                  <img
                    className={editableClass('vendor_product_variations_stock', variation.vendor_product_variations_id)}
                    src={editableSrc('vendor_product_variations_stock', variation.vendor_product_variations_id)}
                  />
                </>
              )}
            </td>

            <td className="product-simple-item text-center">
              <span className="product-simple-item-edit-btn" onClick={() => onVariableProductSelect(props.product, variation.vendor_product_variations_id)}>
                <img src="/fa/svgs/regular/edit.svg" />
              </span>
            </td>
          </tr>
        ))}
    </>
  );
};

export default EditVariableProducts;
