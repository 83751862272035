import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

import { DashboardPage } from '../dashboard';
import { ImportProductsPage } from '../import-products';
import { MyProductsPage } from '../my-products';
import { WooCommerceConfigPage } from '../woocommerce-config';
import { useUsersContext } from 'contexts/users';
import { LoginPage } from 'pages/login';
import { AccountPage } from 'pages/account';
import { CsvImportPage } from 'pages/csv-import';
import { Col, Container, Row, Nav, Offcanvas, NavDropdown, Navbar, Dropdown } from 'react-bootstrap';

import { UsersActions } from 'contexts/users/types';

import companyLogo from '../../img/logo_green.png';

export const MainPage: React.FC = () => {
  const { usersState, usersDispatch } = useUsersContext();
  const [show, setShow] = React.useState(false);

  const toggleOffCanvas = (): void => {
    setShow((show) => !show);
  };

  const SignOut = (): void => {
    usersDispatch({ type: UsersActions.LOGOUT, payload: '' });
  };

  const isAdmin = (): boolean => {
    return usersState.user.role.includes('admin');
  };

  const isApiConnected = (): boolean => {
    if (usersState.user.apiConnected) {
      return usersState.user.apiConnected;
    }

    return false;
  };

  if (usersState.token.length === 0) {
    return <LoginPage />;
  }

  return (
    <BrowserRouter>
      <Container fluid className="main-wrapper">
        <Row className="header">
          <Col xs={6}>
            <Link to="/">
              <img src={companyLogo} alt="IT-Stack Logo" width="120" loading="lazy" />
            </Link>
          </Col>
          <Col xs={6} className="d-flex justify-content-end my-auto">
            <a href="/#" onClick={SignOut} className="sign-out">
              <b>Log ud</b>
            </a>
          </Col>
        </Row>

        <Row className="main-content">
          <Col className="sidebar-wrapper custom-sidebar">
            <Row className="d-none d-sm-none d-md-none d-lg-block">
              <Col sm={12} className="user-wrapper">
                <p>
                  <b>{usersState.user.company}</b>
                </p>
                <p>ID: {usersState.user.id}</p>
              </Col>

              <hr />

              <Col sm={12} className="menu-wrapper">
                <Navbar>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto flex-column">
                      <LinkContainer to="/" className="d-flex menu-gap">
                        <Nav.Link>
                          <img src="/fa/svgs/menu/home.svg" className="me-1" /> Dashboard
                        </Nav.Link>
                      </LinkContainer>
                      {isApiConnected() === true && (
                        <LinkContainer to="/import-products" className="d-flex menu-gap">
                          <Nav.Link>
                            <img src="/fa/svgs/menu/download.svg" className="me-1" /> Import
                          </Nav.Link>
                        </LinkContainer>
                      )}

                      <Dropdown className="navbar-dropdown d-flex menu-gap" autoClose={false} navbar={true}>
                        <Dropdown.Toggle id="dropdown-autoclose-false">
                          <img src="/fa/svgs/menu/settings.svg" className="me-1 mb-1" /> Indstillinger
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <LinkContainer to="/account">
                            <Dropdown.Item>Konto</Dropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/woocommerce-config">
                            <Dropdown.Item>Integration</Dropdown.Item>
                          </LinkContainer>
                          {isAdmin() === true && (
                            <LinkContainer to="/csv-import">
                              <Dropdown.Item>CSV Import</Dropdown.Item>
                            </LinkContainer>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
            </Row>

            <Row className="d-block d-sm-block d-md-block d-lg-none">
              <Navbar collapseOnSelect expand={false}>
                <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={toggleOffCanvas} />
                <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="start" show={show} onHide={toggleOffCanvas}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Col sm={12} className="user-wrapper">
                      <p>
                        <b>{usersState.user.company}</b>
                      </p>
                      <p>ID: {usersState.user.id}</p>
                    </Col>

                    <hr />

                    <Nav className="justify-content-end flex-grow-1 pe-3 offcanvas--menu mobile-menu">
                      <LinkContainer to="/" onClick={toggleOffCanvas}>
                        <Nav.Link>Dashboard</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/import-products" onClick={toggleOffCanvas}>
                        <Nav.Link>Import</Nav.Link>
                      </LinkContainer>
                      <NavDropdown title="Indstillinger" id="basic-nav-dropdown">
                        <LinkContainer to="/account" onClick={toggleOffCanvas}>
                          <Nav.Link>Konto</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/woocommerce-config" onClick={toggleOffCanvas}>
                          <Nav.Link>Integration</Nav.Link>
                        </LinkContainer>
                        {isAdmin() === true && (
                          <LinkContainer to="/csv-import" onClick={toggleOffCanvas}>
                            <Nav.Link>CSV Import</Nav.Link>
                          </LinkContainer>
                        )}
                      </NavDropdown>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Navbar>
            </Row>
          </Col>

          <Col sm={12} lg={10} className="content-wrapper">
            <Switch>
              <Route exact path="/" component={DashboardPage} />
              <Route path="/my-products" component={MyProductsPage} />
              <Route path="/import-products" component={ImportProductsPage} />
              <Route path="/woocommerce-config" component={WooCommerceConfigPage} />
              <Route path="/account" component={AccountPage} />
              {isAdmin() === true && <Route path="/csv-import" component={CsvImportPage} />}
            </Switch>
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  );
};
