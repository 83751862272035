import React, { useEffect, useState } from 'react';
import { useUsersContext } from 'contexts/users';
import { UsersActions } from 'contexts/users/types';
//Models
import { UsersIntergrationsInterface } from './types';
import { CategoryInterface } from 'pages/import-products/shared/types';
//Api
import { getWebshopConfig, setWebshopConfig, getWebshopProductCategories } from 'Api';
//Styles
import { Row, Col, Card, Form, ListGroup, Button, Spinner, Toast } from 'react-bootstrap';
import debugLog from 'lib/debug';

export const WooCommerceConfigPage: React.FC = () => {
  const { usersDispatch } = useUsersContext();
  const [config, setConfig] = useState<UsersIntergrationsInterface>({
    users_integrations_url: '',
    users_integrations_key: '',
    users_integrations_secret: '',
  });
  const [statusText, setStatusText] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [webshopCategories, setWebshopCategories] = useState<CategoryInterface[]>([]);
  const [showKey, setShowKey] = useState<boolean>(false);
  const [showSecret, setShowSecret] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    const getWebshopConfigHandler = async (): Promise<void> => {
      const response = await getWebshopConfig();
      if (isMounted) {
        if (response) {
          setConfig(response.data);
        }
      }
    };

    const getWebshopProductCategoriesHandler = async () => {
      const response = await getWebshopProductCategories();
      if (isMounted) {
        if (response) {
          if (response.data.error) {
            setStatusText('Connection status: ' + response.data.error);
          } else {
            setStatusText('Connection status: ' + response.status + ' ' + response.statusText);
            setWebshopCategories(response.data);
          }
        }
      }
    };

    getWebshopConfigHandler();
    getWebshopProductCategoriesHandler();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleValueChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfig({ ...config, [event.target.id]: event.target.value });
    debugLog('Value changed: ' + event.target.id + ' ' + event.target.value, { enable: false });
  };

  const handleFormSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();

    setLoading(true);

    const response = await setWebshopConfig(config);
    if (response) {
      if (response.data.error) {
        setStatusText('Connection status: ' + response.status + ': ' + response.data.status);
      } else {
        setConfig(config);
        setStatusText('Connection status: ' + response.status + ': ' + response.data.status);
        if (response.data.token) {
          usersDispatch({ type: UsersActions.LOGIN, payload: response.data.token });
        }
      }
    }

    setLoading(false);
  };

  function showSecretBtn() {
    setShowSecret(!showSecret);
  }

  function showKeyBtn() {
    setShowKey(!showKey);
  }

  return (
    <>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header>WooCommerce integration</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="users_integrations_url">
                  <Form.Label>URL</Form.Label>
                  <Form.Control type="url" placeholder="Indtast URL" value={config.users_integrations_url} onChange={handleValueChanged} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="users_integrations_key">
                  <Form.Label>ConsumerKey</Form.Label>
                  <Form.Control
                    type={showKey ? 'text' : 'password'}
                    placeholder="Indtast CustomerKey"
                    value={config.users_integrations_key}
                    onChange={handleValueChanged}
                  />
                  <Form.Check type="checkbox" label="Vis key" className="mt-2" onClick={showKeyBtn} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="users_integrations_secret">
                  <Form.Label>ConsumerSecret</Form.Label>
                  <Form.Control
                    type={showSecret ? 'text' : 'password'}
                    placeholder="Indtast ConsumerSecret"
                    value={config.users_integrations_secret}
                    onChange={handleValueChanged}
                  />
                  <Form.Check type="checkbox" label="Vis secret" className="mt-2" onClick={showSecretBtn} />
                </Form.Group>

                <Button variant="primary" type="submit" onClick={handleFormSubmit}>
                  Gem
                  {loading && (
                    <>
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </>
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header>Debug</Card.Header>
            <Card.Body>
              {loading && (
                <>
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </>
              )}
              {statusText}
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header>WooCommerce kategorier</Card.Header>
            <Card.Body>
              <Toast className="mb-4">
                <Toast.Header closeButton={false}>
                  <strong className="me-auto">IT Stack</strong>
                  <small>Note</small>
                </Toast.Header>
                <Toast.Body>Hej! Husk at oprette alle kategorier i din webshop før du starter dit import.</Toast.Body>
              </Toast>
              <ListGroup variant="flush">
                {webshopCategories?.length > 0
                  ? webshopCategories.map((category) => <ListGroup.Item key={category.id}>{category.name}</ListGroup.Item>)
                  : 'Kategorier vises når du har oprettet forbindelse til WooCommerce'}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
