import React from 'react';
import { VariableProductContentProps } from '../types';

const VariableProductContent: React.FC<React.PropsWithChildren<VariableProductContentProps>> = (props) => {
  const numberFormatter = Intl.NumberFormat('da-DK');

  return (
    <>
      <td className="product-variable-item">
        {props.showImage === true && props.variation.vendor_product_variations_image && props.variation.vendor_product_variations_image.length !== 0 ? (
          <label>
            <img width={50} height={50} src={props.variation.vendor_product_variations_image} alt="alt" />
          </label>
        ) : null}
      </td>
      <td className="d-none d-sm-table-cell product-variable-item">
        <div className="multi-line-wrapper">
          {props.showSku && <label>{props.variation.vendor_product_variations_sku}</label>}
          {props.showEan && <small>{props.variation.vendor_product_variations_ean}</small>}
        </div>
      </td>
      <td className="product-variable-item">{props.showName && <label>{props.variation.vendor_product_variations_name}</label>}</td>
      <td className="d-none d-sm-table-cell product-variable-item">
        {props.showAttribute && (
          <label>
            {props.variation.vendor_product_variations_attributes}: {props.variation.vendor_product_variations_attributes_value}
          </label>
        )}
      </td>
      <td className="d-none d-sm-table-cell product-variable-item">
        {props.showPrice && <label>{numberFormatter.format(props.variation.vendor_product_variations_price)} DKK</label>}
      </td>
      <td className="d-table-cell product-variable-item justify-content-end">
        {props.showButton && (
          <div className="edit-custom-checkbox justify-content-end">
            <label>
              <input type="checkbox" disabled readOnly />
              <span className="edit-custom-checkbox-check" aria-hidden="true">
                <img className={props.selected ? '' : 'hidden'} src="/fa/svgs/solid/check.svg" />
              </span>
            </label>
          </div>
        )}
      </td>
    </>
  );
};

export default VariableProductContent;
