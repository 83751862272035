import { SearchProps } from './types';
import React from 'react';

const Search: React.FC<SearchProps> = ({ placeholder, value, onChangeFunction }) => {
  return (
    <div className="d-flex search">
      <div className="search-icon">
        <img src="/fa/svgs/solid/magnifying-glass.svg" />
      </div>
      <input type="text" className="search-input form-control" placeholder={placeholder} value={value} onChange={onChangeFunction} />
    </div>
  );
};

export default Search;
