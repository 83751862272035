import React, { createContext, useState, useContext } from 'react';
import { ProductInterface } from 'pages/import-products/shared/types';
import { SelectedProductsContextType, SelectedProductsProviderProps } from './types';

const defaultContextValue: SelectedProductsContextType = {
  selectedProducts: [],
  setSelectedProducts: () => null,
};

const SelectedProductsContext = createContext<SelectedProductsContextType>(defaultContextValue);

export const SelectedProductsProvider: React.FC<SelectedProductsProviderProps> = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState<ProductInterface[]>([]);

  return <SelectedProductsContext.Provider value={{ selectedProducts, setSelectedProducts }}>{children}</SelectedProductsContext.Provider>;
};

export const useSelectedProducts = (): SelectedProductsContextType => {
  const context = useContext(SelectedProductsContext);

  if (context === undefined) {
    throw new Error('useSelectedProducts skal bruges inden for en SelectedProductsProvider');
  }

  return context;
};
